<template>
  <div class="home_box">
    <div class="h_row1">
      <div class="r_1">
        <div>{{ $t("main.text2") }}</div>
        <div><i @click="$router.push('/set')" class="el-icon-s-tools"></i></div>
      </div>
      <div class="r_1_2">
        <div class="r_1_2_1">{{ num_format(hicoin) }}</div>
        <div class="r_1_2_2">Hicoin</div>
      </div>
      <div class="r_1_3">
        <div @click="$router.push('/buy')">
          <img src="../../assets/img/home_icon1.png" alt="" />
          {{ $t("main.text47") }}
        </div>
        <div @click="$router.push('/sell')">
          <img src="../../assets/img/home_icon2.png" alt="" />
          {{ $t("main.text52") }}
        </div>
      </div>
    </div>

    <div class="h_row1">
      <div class="r_1" @click="$router.push('/order_list')">
        <div>{{ $t("main.text53") }}</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="h_row2_list">
        <div class="list1">
          <div class="list1_1">
            <i class="el-icon-warning" v-if="item.voucherOrders > 0"></i>
          </div>
          <!-- status: 1, 订单状态；1订单确认中；2待上传凭证；3待处理；4用户申诉；5代理申诉 6用户取消；7代理取消；8后台取消；9用户完成；10代理完成；11后台完成 -->
          <div class="list1_2">
            {{ item.voucherOrders }}
            {{ $t("main.text54") }}
            <span>{{ $t("main.text55") }}</span>
          </div>
        </div>

        <div class="list1">
          <div class="list1_1">
            <i class="el-icon-warning" v-if="item.pendingOrders > 0"></i>
          </div>
          <div class="list1_2">
            {{ item.pendingOrders }}
            {{ $t("main.text54") }}
            <span>{{ $t("main.text34") }}</span>
          </div>
        </div>

        <div class="list1">
          <div class="list1_1">
            <i class="el-icon-warning" v-if="item.appealOrders > 0"></i>
          </div>
          <div class="list1_2">
            {{ item.appealOrders }}
            {{ $t("main.text54") }}
            <span>{{ $t("main.text65") }}</span>
          </div>
        </div>
      </div>
      <!-- <div class="h_row2_list" >
        <div class="noData">{{ $t("main.text59") }}</div>
      </div> -->
    </div>

    <div class="h_row1">
      <div class="r_1" @click="$router.push('/order_list/order_done')">
        <div>{{ $t("main.text60") }}</div>
        <div><i class="el-icon-arrow-right"></i></div>
      </div>
      <div class="h_row2_list" v-if="completeInfos.length > 0">
        <div class="list1" v-for="(item, index) in completeInfos" :key="index">
          <div class="list1_1">
            <i
              class="el-icon-warning"
              v-if="item.status == 2 || item.status == 4 || item.status == 5"
            ></i>
          </div>
          <!-- <div class="list1_2">购买订单2321324已完成交易</div> -->
          <!-- type: 1, 订单类型 1用户购买；2用户出售 -->
          <div class="list1_2">
            <span v-if="item.type == 1">{{ $t("main.text47") }}</span>
            <span v-else-if="item.type == 2">{{ $t("main.text52") }}</span>
            {{ $t("main.text61") }} {{ subStr(item.order_id) }}
            <span v-if="item.status == 6">{{ $t("main.text37") }}</span>
            <span v-else-if="item.status == 7">{{ $t("main.text38") }}</span>
            <span v-else-if="item.status == 8">{{ $t("main.text39") }}</span>
            <span v-else-if="item.status == 9">{{ $t("main.text40") }}</span>
            <span v-else-if="item.status == 10">{{ $t("main.text41") }}</span>
            <span v-else-if="item.status == 11">{{ $t("main.text42") }}</span>
          </div>
        </div>
      </div>
      <div class="h_row2_list" v-else>
        <div class="noData">{{ $t("main.text59") }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { home } from "../../api/api";
export default {
  data() {
    return {
      hicoin: 0,
      completeInfos: [],
      item: {},
    };
  },
  beforeMount() {
    sessionStorage.setItem("hichat_title", this.$t("main.text1"));
  },
  mounted() {
    this.get_list();
  },
  methods: {
    async get_list() {
      const res = await home();
      if (res.data.code !== 200) {
        return this.msg("error", res.data.msg);
      }
      this.hicoin = res.data.data.hicoin;
      this.item = res.data.data;

      this.completeInfos = res.data.data.completeInfos;
    },
    subStr(v) {
      if (v) {
        let len = v.length;
        let center = v.substring(4, len - 4);
        return v.replace(center, "....");
      }
    },
  },
};
</script>

<style scoped>
.home_box {
  box-sizing: border-box;
  padding: 1rem;
}
.h_row1 {
  margin-bottom: 1.25rem;
  box-sizing: border-box;
  padding-bottom: 1.25rem;
  background: #fff;
}
.r_1 {
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.375rem 0.375rem 0rem 0rem;
  background: #2982d8;
  font-size: 0.875rem;
  display: flex;
  justify-content: space-between;
  color: #fff;
  box-sizing: border-box;
  padding: 0 1rem;
}
.r_1 i {
  font-size: 1.2rem;
  vertical-align: sub;
}
.r_1_2 {
  padding: 1.7rem 0 3rem;
  text-align: center;
  color: #000;
}
.r_1_2_1 {
  font-size: 2rem;
  margin-bottom: 0.25rem;
}
.r_1_2_2 {
  font-size: 0.75rem;
  opacity: 0.6;
}
.r_1_3 {
  display: flex;
  justify-content: center;
}
.r_1_3 > div {
  min-width: 7.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.25rem;
  background: #2982d8;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
}
.r_1_3 > div:first-child {
  margin-right: 1.2rem;
}
.r_1_3 img {
  width: 1.25rem;
  margin-right: 0.2rem;
  vertical-align: sub;
}
.list1 {
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #F5F6F7; */
  box-sizing: border-box;
  padding: 0.75rem 1rem 0;
}
.list1_1 {
  width: 1.7rem;
  box-sizing: border-box;
  padding-bottom: 0.75rem;
}
.list1_1 i {
  display: block;
  font-size: 1.2rem;
  color: rgba(246, 39, 39, 0.9);
}
.list1_2 {
  color: #000;
  font-size: 0.75rem;
  flex: 1;
  box-sizing: border-box;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #f5f6f7;
}
.h_row2_list .list1:last-child .list1_1,
.h_row2_list .list1:last-child .list1_2 {
  padding-bottom: 0;
  border-bottom: 0;
}
</style>
